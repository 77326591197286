import * as React from 'react'

import { numberFormatter } from './format'

import type { FormikProps } from 'formik'
import type { ExecutionResult, GraphQLErrorExtensions } from 'graphql'

type FormRef<T> = React.RefObject<FormikProps<T>>

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const setFormError = <T, >(formRef: FormRef<T>, errorMsg: React.ReactNode) => (
  formRef.current?.setStatus({ errorMsg })
)

export const firstError = (response: ExecutionResult) => (
  (response.errors) ? response.errors[0] : undefined
)

export const translateError = (response: ExecutionResult) => {
  const error = firstError(response)

  switch (error?.message) {
  case '2FA_ALREADY_ENABLED':
    return 'La autenticación de dos factores ya se habilitó para esta cuenta.'
  case 'ADVANCED_ONBOARDING_REQUIRED':
    return 'Esta acción requiere una verificación de cuenta avanzada.'
  case 'AMOUNT_BELOW_MIN':
    return 'El monto no supera el valor mínimo. Actualiza la página por favor.'
  case 'AMOUNT_EXCEEDS_DAILY_MAX':
    return 'El monto supera el límite diario. Actualiza la página por favor.'
  case 'AMOUNT_EXCEEDS_MAX':
    return 'El monto supera el valor máximo. Actualiza la página por favor.'
  case 'BULK_PURCHASE_CREATION_DISABLED':
    return 'No fue posible crear la orden. Actualiza la página por favor.'
  case 'COUNTRY_NOT_SUPPORTED':
    return 'Este medio de pago aún no está habilitado en tu país. Intenta con otro método.'
  case 'DEPOSIT_ADDRESS_UNAVAILABLE':
    return (
      <React.Fragment>
        La primer dirección de depósito para tu cuenta debe ser autorizada.
        <br />
        Generamos una solicitud y te avisaremos por mail para que puedas transferir a tu cuenta.
      </React.Fragment>
    )
  case 'FEES_EXCEED_PURCHASE_AMOUNT':
    return 'El monto indicado no cubre los fees de transacción. Por favor ingresa un monto mayor.'
  case 'IDENTITY_NOT_EDITABLE':
    return 'Tu identidad ya no se puede modificar.'
  case 'IDENTITY_PROOF_NOT_FOUND':
    return 'El documento de identidad es inválido. Actualiza la página por favor.'
  case 'INTERNAL_ERROR':
    return 'Ocurrió un error al generar la orden.'
  case 'INVALID_AMOUNT':
    return invalidAmountMsg(error.extensions)
  case 'CONTENT_TYPE_BACK_REQUIRED':
    return 'Debes subir la imagen del reverso del documento.'
  case 'INVALID_BANK_ACCOUNT_ID':
    return 'La cuenta bancaria no es válida. Actualiza la página por favor.'
  case 'INVALID_AUTHORIZATION_CODE':
    return 'El código es incorrecto.'
  case 'INVALID_BLOCKCHAIN':
    return 'El blockchain seleccionado es incompatible.'
  case 'INVALID_CHECKSUM':
    return 'La dirección es inválida. Verifica que esté bien escrita.'
  case 'INVALID_DOCUMENT':
    return 'Los documentos creados por administrador no se pueden borrar.'
  case 'INVALID_DOCUMENT_ID':
    return 'El documento no existe. Actualiza la página por favor.'
  case 'INVALID_LEGAL_IDENTITY_ID':
    return 'Los datos de identidad son inválidos. Actualiza la página por favor.'
  case 'INVALID_MARKET_ASSET_ID':
    return 'El activo ingresado no existe. Actualiza la página por favor.'
  case 'INVALID_NOTIFICATION_ID':
    return 'La notification de usuario no existe. Actualiza la página por favor.'
  case 'INVALID_NOTIFICATION_TYPE':
    return 'El tipo de notificación es inválido. Si el problema persiste, utiliza el correo de soporte.'
  case 'INVALID_OTP':
    return 'El código 2FA es inválido.'
  case 'INVALID_RANGE':
    return 'El rango seleccionado es inválido.'
  case 'INVALID_SYMBOL':
    return 'La divisa seleccionada no está disponible.'
  case 'INVALID_USER_ID':
    return 'Los datos de usuario son inválidos. Actualiza la página por favor.'
  case 'OPERATION_NOT_ALLOWED':
    return 'Esta operación requiere permisos adicionales.'
  case 'QUOTE_EXPIRED':
    return 'El valor de conversión expiró. Por favor vuelve a intentar.'
  case 'RECEIVER_NOT_VERIFIED':
    return 'La cuenta del destinatario debe ser verificada antes de realizar esta operación.'
  case 'USER_NOT_ALLOWED':
    return 'Tu cuenta no esta habilitida para realizar esta operación.'
  case 'USER_NOT_CONFIRMED':
    return 'Debes confirmar tu email antes de realizar esta operación.'
  case 'USER_NOT_VERIFIED':
    return 'Tu cuenta debe ser verificada antes de realizar esta operación.'
  case 'USER_TYPE_ALREADY_DEFINED':
    return 'El tipo de usuario ya está definido para esta cuenta.'
  case 'WITHDRAWALS_DISABLED':
    return 'No fue posible realizar el retiro. Actualiza la página por favor.'
  case 'VIDEO_NOT_FOUND':
    return 'No fue posible encontrar el video. Actualiza la página por favor.'
  default:
    return (error?.message) ? error.message : 'Ocurrió un error al procesar la solicitud.'
  }
}

export const invalidAmountMsg = (extensions?: GraphQLErrorExtensions) => {
  const formatter = numberFormatter(0)
  const minAmount = extensions?.minAmount && formatter.format(extensions.minAmount)
  const maxAmount = extensions?.maxAmount && formatter.format(extensions.maxAmount)

  if (minAmount && maxAmount) {
    return (
      <React.Fragment>
        El monto debe estar entre <small>$</small>{minAmount} y <small>$</small>{maxAmount}
      </React.Fragment>
    )
  } else if (minAmount) {
    return (
      <React.Fragment>
        El monto debe ser mayor al mínimo de <small>$</small>{minAmount}
      </React.Fragment>
    )
  } else if (maxAmount) {
    return (
      <React.Fragment>
        El monto debe ser inferior al máximo de <small>$</small>{maxAmount}
      </React.Fragment>
    )
  } else {
    return 'El monto ingresado es inválido.'
  }
}
