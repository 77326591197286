import * as React from 'react'

import {
  AccountBalance,
  AttachMoney,
  Download,
  NoteAdd,
  SwapCalls,
  Upload,
} from '@mui/icons-material'
import { LinearProgress, ListItem, ListItemText, SvgIcon } from '@mui/material'
import { timeFormat } from 'd3-time-format'

import { Currency, DatedList, DatedListItem } from '../components'
import { PURCHASE_STATE_LABELS, SALE_STATE_LABELS } from '../queries'
import { percentFormatter } from '../services'

import type { CurrencyProps } from '../components'
import type {
  BankDeposit,
  BulkPurchase,
  Deposit,
  InterestPayout,
  Purchase,
  ReferralPayout,
  Sale,
  Swap,
  UserOperation,
  Withdrawal,
} from '../queries'
import type { ListItemTextProps } from '@mui/material'

const formatDateTime = timeFormat('%Y-%m-%d a las %H:%M')

type CenterTextProps = ListItemTextProps & {
  centerVertically?: boolean
}

export const CenterText = ({
  centerVertically,
  children,
  sx,
}: CenterTextProps) => (
  <ListItemText
    sx={[
      {
        width: '50%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: {
          xs: 'none',
          lg: 'block',
        },
        ...(centerVertically && {
          '&>.MuiTypography-root': {
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          },
        }),
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    secondary={children}
  />
)

type CurrenciesDisplayProps = {
  primary: CurrencyProps
  secondary?: CurrencyProps
}

const CurrenciesDisplay = ({
  primary,
  secondary,
}: CurrenciesDisplayProps) => {
  primary.currency = primary.currency.replace('_', ' ')
  if (secondary?.currency) {
    secondary.currency = secondary.currency.replace('_', ' ')
  }

  return (
    <ListItemText
      sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
      primary={<Currency {...primary} />}
      secondary={secondary && <Currency {...secondary} />}
    />
  )
}

const operationText = (text: string, userId: string, withUserId?: boolean) => (
  (withUserId)
    ? <React.Fragment>{text}<br /><small>Usuario&nbsp;{userId}</small></React.Fragment>
    : text
)

type OperationClickHandler = (op: UserOperation) => void

type OperationDisplay<OperationType> = {
  operation: OperationType
  onClick?: OperationClickHandler
  withUserId?: boolean
}

const BankDepositDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<BankDeposit>) => (
  <DatedListItem
    icon={<AccountBalance />}
    text={operationText('Depósito bancario', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    {(operation.spentAt) ? (
      <CenterText>
        Utilizado el {formatDateTime(new Date(operation.spentAt))}
      </CenterText>
    ) : (
      <CenterText>
        {'Saldo a crédito: '}
        <Currency
          currency='CLP'
          digits={0}
          value={operation.amountLeft}
        />
      </CenterText>
    )}
    <CurrenciesDisplay
      primary={{ currency: 'CLP', value: operation.amount, signed: true }}
    />
  </DatedListItem>
)

const BulkPurchaseDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<BulkPurchase>) => (
  <DatedListItem
    icon={<NoteAdd />}
    text={operationText('Orden de compra', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    {(operation.closedAt) ? (
      <CenterText>
        Completada el {formatDateTime(new Date(operation.closedAt))}
      </CenterText>
    ) : (
      <CenterText centerVertically>
        <LinearProgress
          color='success'
          variant='determinate'
          value={100 * (1 - (operation.amountLeft / operation.inAmount))}
          sx={{ width: '100%', mr: 1 }}
        />
        {percentFormatter.format(1 - (operation.amountLeft / operation.inAmount))}
      </CenterText>
    )}
    <CurrenciesDisplay
      primary={{ currency: 'CLP', value: operation.inAmount }}
      secondary={{ currency: 'CLP/USDT', value: operation.purchasePrice }}
    />
  </DatedListItem>
)

const DepositDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<Deposit>) => (
  <DatedListItem
    icon={<Download />}
    text={operationText('Depósito', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CurrenciesDisplay
      primary={{ currency: operation.symbol, value: operation.changedAmount, signed: true }}
    />
  </DatedListItem>
)

const InterestPayoutDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<InterestPayout>) => (
  <DatedListItem
    icon={(
      <SvgIcon>
        {/* eslint-disable-next-line max-len */}
        <path d='M9,4c-4.42,0-8,3.58-8,8c0,4.42,3.58,8,8,8s8-3.58,8-8C17,7.58,13.42,4,9,4z M12,10.5h-2v5H8v-5H6V9h6V10.5z M20.25,3.75 L23,5l-2.75,1.25L19,9l-1.25-2.75L15,5l2.75-1.25L19,1L20.25,3.75z M20.25,17.75L23,19l-2.75,1.25L19,23l-1.25-2.75L15,19l2.75-1.25 L19,15L20.25,17.75z' />
      </SvgIcon>
    )}
    text={operationText('Recompensa', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CurrenciesDisplay
      primary={{ currency: operation.symbol, value: operation.changedAmount, signed: true }}
    />
  </DatedListItem>
)

const PurchaseDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<Purchase>) => (
  <DatedListItem
    icon={<AttachMoney />}
    text={operationText('Compra', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CenterText>
      <strong>{PURCHASE_STATE_LABELS[operation.state]}</strong>
      {` - Efectuada a través de ${operation.provider}`}
    </CenterText>
    <CurrenciesDisplay
      primary={{ currency: operation.symbol, value: operation.cryptoAmount }}
      secondary={{ currency: operation.fiatCurrency, value: operation.fiatAmount }}
    />
  </DatedListItem>
)

const ReferralPayoutDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<ReferralPayout>) => (
  <DatedListItem
    icon={(
      <SvgIcon>
        {/* eslint-disable max-len */}
        <path d='M6.32,13.01c0.96,0.02,1.85,0.5,2.45,1.34C9.5,15.38,10.71,16,12,16c1.29,0,2.5-0.62,3.23-1.66 c0.6-0.84,1.49-1.32,2.45-1.34C16.96,11.78,14.08,11,12,11C9.93,11,7.04,11.78,6.32,13.01z' />
        <path d='M4,13L4,13c1.66,0,3-1.34,3-3c0-1.66-1.34-3-3-3s-3,1.34-3,3C1,11.66,2.34,13,4,13z' />
        <path d='M20,13L20,13c1.66,0,3-1.34,3-3c0-1.66-1.34-3-3-3s-3,1.34-3,3C17,11.66,18.34,13,20,13z' />
        <path d='M12,10c1.66,0,3-1.34,3-3c0-1.66-1.34-3-3-3S9,5.34,9,7C9,8.66,10.34,10,12,10z' />
        <path d='M21,14h-3.27c-0.77,0-1.35,0.45-1.68,0.92C16.01,14.98,14.69,17,12,17c-1.43,0-3.03-0.64-4.05-2.08 C7.56,14.37,6.95,14,6.27,14H3c-1.1,0-2,0.9-2,2v4h7v-2.26c1.15,0.8,2.54,1.26,4,1.26s2.85-0.46,4-1.26V20h7v-4 C23,14.9,22.1,14,21,14z' />
        {/* eslint-enable max-len */}
      </SvgIcon>
    )}
    text={operationText('Pago de referidos', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CurrenciesDisplay
      primary={{ currency: operation.symbol, value: operation.changedAmount, signed: true }}
    />
  </DatedListItem>
)

const SaleDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<Sale>) => (
  <DatedListItem
    icon={<AttachMoney />}
    text={operationText('Venta', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CenterText>
      <strong>{SALE_STATE_LABELS[operation.state]}</strong>
      {` - Efectuada a través de ${operation.provider}`}
    </CenterText>
    <CurrenciesDisplay
      primary={{ currency: operation.symbol, value: operation.cryptoAmount }}
      secondary={{ currency: operation.fiatCurrency, value: operation.fiatAmount }}
    />
  </DatedListItem>
)

const SwapDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<Swap>) => (
  <DatedListItem
    icon={<SwapCalls />}
    text={operationText('Conversión', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CurrenciesDisplay
      primary={{ currency: operation.toSymbol, value: operation.toChangedAmount, signed: true }}
      secondary={{ currency: operation.fromSymbol, value: -operation.fromChangedAmount }}
    />
  </DatedListItem>
)

const WithdrawalDisplay = ({
  operation,
  onClick,
  withUserId,
}: OperationDisplay<Withdrawal>) => (
  <DatedListItem
    icon={<Upload />}
    text={operationText('Retiro', operation.userId, withUserId)}
    timestamp={operation.timestamp}
    onClick={onClick && (() => onClick(operation))}
  >
    <CenterText>
      {operation.address}
    </CenterText>
    <CurrenciesDisplay
      primary={{ currency: operation.symbol, value: -operation.changedAmount }}
    />
  </DatedListItem>
)

const getOperationComponent = (
  operation: UserOperation,
  onClick?: OperationClickHandler,
  withUserId?: boolean,
) => {
  switch (operation.__typename) {
  case 'BankDeposit':
    return (
      <BankDepositDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'BulkPurchase':
    return (
      <BulkPurchaseDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'Deposit':
    return (
      <DepositDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'InterestPayout':
    return (
      <InterestPayoutDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'Purchase':
    return (
      <PurchaseDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'ReferralPayout':
    return (
      <ReferralPayoutDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'Sale':
    return (
      <SaleDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'Swap':
    return (
      <SwapDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  case 'Withdrawal':
    return (
      <WithdrawalDisplay
        operation={operation}
        onClick={onClick}
        withUserId={withUserId}
      />
    )
  }
}

const EmptyOperationsDisplay = () => (
  <ListItem>
    <ListItemText
      primary='No hay operaciones para mostrar'
      primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
    />
  </ListItem>
)

type OperationsListProps = {
  loading?: boolean
  onClick?: OperationClickHandler
  operations: UserOperation[]
  withUserId?: boolean
}

export const OperationsList = ({
  loading,
  onClick,
  operations,
  withUserId,
}: OperationsListProps) => (
  <DatedList
    emptyListDisplay={<EmptyOperationsDisplay />}
    loading={loading}
    items={operations.map((op) => ({
      timestamp: op.timestamp,
      component: getOperationComponent(op, onClick, withUserId),
    }))}
  />
)
