import { gql } from '@apollo/client'

export type BankDeposit = {
  __typename: 'BankDeposit'
  id: string
  amount: number
  amountLeft: number
  spentAt: string | null
  timestamp: number
  userId: string
}

export const BANK_DEPOSIT_FIELDS = gql`
  fragment BankDepositFields on BankDeposit {
    id
    amount
    amountLeft
    spentAt
    timestamp
    userId
  }
`

export type BulkPurchase = {
  __typename: 'BulkPurchase'
  id: string
  amountLeft: number
  closedAt: string | null
  inAmount: number
  outAmount: number
  purchasePrice: number
  timestamp: number
  userId: string
}

export const BULK_PURCHASE_FIELDS = gql`
  fragment BulkPurchaseFields on BulkPurchase {
    id
    amountLeft
    closedAt
    inAmount
    outAmount
    purchasePrice
    timestamp
    userId
  }
`

export type Deposit = {
  __typename: 'Deposit'
  id: string
  timestamp: number
  symbol: string
  changedAmount: number
  address: string
  userId: string
}

export const DEPOSIT_FIELDS = gql`
  fragment DepositFields on Deposit {
    id
    timestamp
    symbol
    changedAmount
    address
    userId
  }
`

export type InterestPayout = {
  __typename: 'InterestPayout'
  id: string
  timestamp: number
  symbol: string
  changedAmount: number
  userId: string
}

export const INTEREST_PAYOUT_FIELDS = gql`
  fragment InterestPayoutFields on InterestPayout {
    id
    timestamp
    symbol
    changedAmount
    userId
  }
`

export type PurchaseState = 'PENDING' | 'APPROVED' | 'DECLINED' | 'PAID' | 'REFUNDED'

export const PURCHASE_STATE_LABELS: { [key in PurchaseState]: string } = {
  'PENDING': 'Pendiente',
  'APPROVED': 'Aprobada',
  'DECLINED': 'Rechazada',
  'PAID': 'Pagada',
  'REFUNDED': 'Reembolsada',
}

export type Purchase = {
  id: string
  timestamp: number
  __typename: 'Purchase'
  symbol: string
  cryptoAmount: number
  fiatAmount: number
  fiatCurrency: string
  provider: string
  state: PurchaseState
  txId: string
  userId: string
}

export const PURCHASE_FIELDS = gql`
  fragment PurchaseFields on Purchase {
    id
    timestamp
    symbol
    cryptoAmount
    fiatAmount
    fiatCurrency
    provider
    state
    txId
    userId
  }
`

export type ReferralPayout = {
  __typename: 'ReferralPayout'
  id: string
  timestamp: number
  symbol: string
  changedAmount: number
  userId: string
}

export const REFERRAL_PAYOUT_FIELDS = gql`
  fragment ReferralPayoutFields on ReferralPayout {
    id
    timestamp
    symbol
    changedAmount
    userId
  }
`

export type SaleState = 'PENDING' | 'APPROVED' | 'DECLINED' | 'PAID' | 'REFUNDED'

export const SALE_STATE_LABELS: { [key in SaleState]: string } = {
  'PENDING': 'Pendiente',
  'APPROVED': 'Aprobada',
  'DECLINED': 'Rechazada',
  'PAID': 'Pagada',
  'REFUNDED': 'Reembolsada',
}

export type Sale = {
  id: string
  timestamp: number
  __typename: 'Sale'
  symbol: string
  cryptoAmount: number
  fiatAmount: number
  fiatCurrency: string
  provider: string
  state: SaleState
  txId: string
  userId: string
}

export const SALE_FIELDS = gql`
  fragment SaleFields on Sale {
    id
    timestamp
    symbol
    cryptoAmount
    fiatAmount
    fiatCurrency
    provider
    state
    txId
    userId
  }
`

export type Swap = {
  id: string
  timestamp: number
  __typename: 'Swap'
  fromSymbol: string
  fromChangedAmount: number
  toSymbol: string
  toChangedAmount: number
  userId: string
}

export const SWAP_FIELDS = gql`
  fragment SwapFields on Swap {
    id
    timestamp
    fromSymbol
    fromChangedAmount
    toSymbol
    toChangedAmount
    userId
  }
`

export type Withdrawal = {
  __typename: 'Withdrawal'
  id: string
  timestamp: number
  symbol: string
  changedAmount: number
  address: string
  userId: string
}

export const WITHDRAWAL_FIELDS = gql`
  fragment WithdrawalFields on Withdrawal {
    id
    timestamp
    symbol
    changedAmount
    address
    userId
  }
`

export type UserOperation =
  BankDeposit
  | BulkPurchase
  | Deposit
  | InterestPayout
  | Purchase
  | ReferralPayout
  | Sale
  | Swap
  | Withdrawal

export type UserOperations = {
  __typename: 'UserOperations'
  bankDeposits: BankDeposit[]
  bulkPurchases: BulkPurchase[]
  deposits: Deposit[]
  interestPayouts: InterestPayout[]
  purchases: Purchase[]
  referralPayouts: ReferralPayout[]
  sales: Sale[]
  swaps: Swap[]
  withdrawals: Withdrawal[]
}

export const USER_OPERATIONS_FIELDS = gql`
  ${BANK_DEPOSIT_FIELDS}
  ${BULK_PURCHASE_FIELDS}
  ${DEPOSIT_FIELDS}
  ${INTEREST_PAYOUT_FIELDS}
  ${PURCHASE_FIELDS}
  ${REFERRAL_PAYOUT_FIELDS}
  ${SALE_FIELDS}
  ${SWAP_FIELDS}
  ${WITHDRAWAL_FIELDS}
  fragment UserOperationsFields on UserOperations {
    bankDeposits {
      ...BankDepositFields
    }
    bulkPurchases {
      ...BulkPurchaseFields
    }
    deposits {
      ...DepositFields
    }
    interestPayouts {
      ...InterestPayoutFields
    }
    purchases {
      ...PurchaseFields
    }
    referralPayouts {
      ...ReferralPayoutFields
    }
    sales {
      ...SaleFields
    }
    swaps {
      ...SwapFields
    }
    withdrawals {
      ...WithdrawalFields
    }
  }
`

export type UserOperationsVars = Record<string, never>

export type UserOperationsData = {
  userOperations: UserOperations
}

export const USER_OPERATIONS_QUERY = gql`
  ${USER_OPERATIONS_FIELDS}
  query UserOperations {
    userOperations {
      ...UserOperationsFields
    }
  }
`

export type UserOperationLabel = {
  typename: UserOperation['__typename']
  label: string
}

export const USER_OPERATION_LABELS: UserOperationLabel[] = [
  {
    typename: 'BankDeposit',
    label: 'Depósito bancario',
  },
  {
    typename: 'BulkPurchase',
    label: 'Orden de compra',
  },
  {
    typename: 'Deposit',
    label: 'Depósito de activos',
  },
  {
    typename: 'InterestPayout',
    label: 'Recompensa',
  },
  {
    typename: 'Purchase',
    label: 'Compra',
  },
  {
    typename: 'ReferralPayout',
    label: 'Pago de referidos',
  },
  {
    typename: 'Sale',
    label: 'Venta',
  },
  {
    typename: 'Swap',
    label: 'Conversión',
  },
  {
    typename: 'Withdrawal',
    label: 'Retiro de activos',
  },
]
