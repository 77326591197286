import * as React from 'react'

import { Image } from '../components'
import BadgeAictech from '../images/market_assets/aictech.svg'
import BadgeBitcoinAnimated from '../images/market_assets/bitcoin-s.svg'
import BadgeBitcoin from '../images/market_assets/bitcoin.svg'
import BadgeCardanoAnimated from '../images/market_assets/cardano-s.svg'
import BadgeCardano from '../images/market_assets/cardano.svg'
import BadgeEridu from '../images/market_assets/eridu.png'
import BadgeEthereumAnimated from '../images/market_assets/ethereum-s.svg'
import BadgeEthereum from '../images/market_assets/ethereum.svg'
import BadgeKamaleont from '../images/market_assets/kamaleont.png'
import BadgePolkadotAnimated from '../images/market_assets/polkadot-s.svg'
import BadgePolkadot from '../images/market_assets/polkadot.svg'
import BadgeProsus from '../images/market_assets/prosus.png'
import BadgeSolanaAnimated from '../images/market_assets/solana-s.svg'
import BadgeSolana from '../images/market_assets/solana.svg'
import BadgeThought from '../images/market_assets/thought.png'
import BadgeUfBonus from '../images/market_assets/uf_bonus.svg'
import BadgeUsdcAnimated from '../images/market_assets/usdc-s.svg'
import BadgeUsdc from '../images/market_assets/usdc.svg'
import BadgeUsdtAnimated from '../images/market_assets/usdt-s.svg'
import BadgeUsdt from '../images/market_assets/usdt.svg'
import BadgeZurf from '../images/market_assets/zurf.svg'

import type { ImageProps } from '../components'

type AssetBadges = {
  [symbol: string]: undefined | {
    static: string
    animated: string
  }
}

const assetBadges: AssetBadges = {
  'BTC': { static: BadgeBitcoin, animated: BadgeBitcoinAnimated },
  'ETH': { static: BadgeEthereum, animated: BadgeEthereumAnimated },
  'ADA': { static: BadgeCardano, animated: BadgeCardanoAnimated },
  'DOT': { static: BadgePolkadot, animated: BadgePolkadotAnimated },
  'SOL': { static: BadgeSolana, animated: BadgeSolanaAnimated },
  'USDT': { static: BadgeUsdt, animated: BadgeUsdtAnimated },
  'USDC': { static: BadgeUsdc, animated: BadgeUsdcAnimated },
  'KLT': { static: BadgeKamaleont, animated: BadgeKamaleont },
  'UF_Aictech': { static: BadgeAictech, animated: BadgeAictech },
  'UF_Bonus': { static: BadgeUfBonus, animated: BadgeUfBonus },
  'ERIDU': { static: BadgeEridu, animated: BadgeEridu },
  'TOT': { static: BadgeThought, animated: BadgeThought },
  'wPROSUS': { static: BadgeProsus, animated: BadgeProsus },
  'ZRF': { static: BadgeZurf, animated: BadgeZurf },
}

type AssetBadgeProps = Omit<ImageProps, 'src' | 'alt'> & {
  symbol: string
  height?: number
  animated?: boolean
  style?: React.CSSProperties
}

export const AssetBadge = ({
  symbol,
  height,
  animated,
  style,
  ...rest
}: AssetBadgeProps) => {
  const assetBadge = assetBadges[symbol]

  if (typeof assetBadge === 'undefined') {
    return null
  }

  const src = assetBadge[animated ? 'animated' : 'static']

  return animated ? (
    <Image
      src={src}
      alt={`Logo ${symbol}`}
      shift={null}
      loading='lazy'
      fit='contain'
      duration={2000}
      style={style}
      wrapperStyle={height ? {
        height: height,
        paddingLeft: height / 10,
        paddingTop: height / 10,
      } : {}}
      {...rest}
    />
  ) : (
    <img
      src={src}
      alt={`Logo ${symbol}`}
      loading='lazy'
      style={style}
      height={height}
      {...rest}
    />
  )
}
