import * as React from 'react'

import { TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import { NumericFormat } from 'react-number-format'

import type { TextFieldProps } from '@mui/material'

type BaseCurrencyFieldProps =
  Omit<TextFieldProps, 'defaultValue' | 'onChange' | 'type' | 'value'> & {
    value: number | undefined
    digits: number
    onChange: (newValue: number | undefined) => void
    positive?: boolean
  }

export const BaseCurrencyField = ({
  value,
  digits,
  onChange,
  positive,
  ...rest
}: BaseCurrencyFieldProps) => (
  <NumericFormat
    value={value}
    onValueChange={(values) => onChange(values.floatValue)}
    fixedDecimalScale
    decimalScale={digits}
    decimalSeparator=','
    thousandSeparator='.'
    allowNegative={!positive}
    customInput={TextField}
    {...rest}
  />
)

type FormikValues = {
  [key: string]: number
}

type CurrencyFieldProps =
  Omit<BaseCurrencyFieldProps, 'value' | 'error' | 'helperText' | 'onBlur' | 'onChange'> & {
    name: string
  }

export const CurrencyField = ({
  name,
  ...rest
}: CurrencyFieldProps) => {
  const {
    errors,
    values,
    setFieldValue,
    setFieldTouched,
    touched,
  } = useFormikContext<FormikValues>()

  return (
    <BaseCurrencyField
      value={values[name]}
      error={touched[name] && !!errors[name]}
      helperText={touched[name] &&  errors[name]}
      onBlur={() => setFieldTouched(name, true)}
      onChange={(newValue) => setFieldValue(name, newValue)}
      {...rest}
    />
  )
}
